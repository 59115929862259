import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import CheckField from "../../components/form/field/CheckField";
import SelectField from "../../components/form/field/SelectField";
import DateTimeField from "../../components/form/field/DateTimeField";
import NumberField from "../../components/form/field/NumberField";
import arrayMutators from "final-form-arrays";


import createServiceFor from "../../service/Service";
import {
  goodRegularOrBad,
  locaisMedicao,
  montanteOuJusante,
  tiposPagamento,
  tiposVisita,
  totalParcialOuNao,
  OPERACAO_DE_ROTEIRO,
  yesOrNo,
  tiposMedicao,
  MEDICAO_RIO_SECO,
  MEDICAO_RIO_CORTADO,
} from "../../model/AvaliacaoQualidade";
import Container from "react-bootstrap/Container";
import createDecorator from "final-form-calculate";
import { FieldArray } from "react-final-form-arrays";

import "./PaginaFormRelatoriosFluviometricos.css";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import FieldLabel from "components/form/field/FieldLabel";
import PromiseLoader from "../../components/PromiseLoader";
import Error from "../../components/form/Error";
import Fields from "../../components/form/Fields";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import CheckSetField from "../../components/form/field/CheckSetField";
import BotaoIconeExclusao from "../../components/botao/BotaoIconeExclusao";
import BotaoPadrao from "../../components/botao/BotaoPadrao";
import FileGridField from "../../components/form/field/FileGridField";
import { connect } from "react-redux";
import { limparArquivos } from "../../reducers/fileReducers";
import AlertaCondicional from "../../components/form/AlertaCondicional";
import {JqxDropDownListAdapter} from "../../components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import { requiredWithNotification, requiredWithNotificationCheckField } from "../../util/FormUtils";
import TextAreaField from "components/form/field/TextAreaField";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import voltarParaGrid from "util/VoltarParaGrid";

// TODO: Refactor validation functions (into util file?) to be used in other forms
const numberOrRCRSWithNumbers = (value) => {
  // return Boolean(value && value.trim() && (!Number.isNaN(Number(value)) || Boolean(value.match(/^[\w\d]*(RC|RS)[\w\d]*$/)))) // Number or RC/RS with alphanumeric
  return Boolean(
    value &&
      value.trim() &&
      (!Number.isNaN(Number(value)) || Boolean(value.match(/^(RC|RS)$/)))
  ) // Number or RC/RS with numbers
    ? undefined
    : "Digite RC para rio cortado, RS para rio seco ou o valor da cota em centímetros";
};

const requiredWithMessage = (message) => (value) =>
  value ? undefined : message;
const atLeastOneElement = (message) => (value) =>
  value && value.length ? undefined : message;
const tipoEstacaoDeveConter = (contain) => (value, allValues) =>
  requiredWithMessage("O campo Código é obrigatório")(value) ||
  (!!allValues.tipoEstacao && allValues.tipoEstacao.includes(contain)
    ? undefined
    : "Digite o código de uma estação tipo " + contain);

    let dadosEstacao;
    let tipoDadoEstacao = false;
// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(
  { field: "habilitarEstacoesSemF",
    updates: (value, name, allValues) => {
     const reguasNaoAplica = value ? allValues.reguasNaoAplica : false;

      if(!allValues.estacaoSelecionada)
        return { reguasNaoAplica };

      const estacaoTipoF = allValues.estacaoSelecionada.tipo.toUpperCase().includes('F');

      if(!estacaoTipoF && !value)
        return {
          estacaoSelecionada: null,
          codigoEstacao: "",
          nomeEstacao: "",
          baciaEstacao: "",
          rioEstacao: "",
          roteiroEstacao: "",
          tipoEstacao: "",
          observador: "",
          reguasNaoAplica,
        }

      return { reguasNaoAplica };
    }
  },
  {
    field: "equipamentosMedicao",
    updates: (value, name, allValues) => {

      if(!value || !value.length)
        return { equipamentoMedicao: null };

      if(allValues.equipamentoMedicao){
        let equipamentoSelecionado = value
        .find(item => item.id == allValues.equipamentoMedicao || item.id == allValues.equipamentoMedicao?.id);

        if(!equipamentoSelecionado)
          return { equipamentoMedicao: null };
      }

      return {};
    }
  },
  {
    field: "codigoEstacaoDigitado",
    updates: async (value, name, allValues) => {
      let listaEquipamentos = [];
      let utilizarUnidadeRegionalDoRelatorio = allValues.id ? true : false;

      const getEquipamentos = async (idUnidadeRegional) => {
        try{
          const resEquipamentos = await createServiceFor(`/equipamentos/descargaLiquida/simples/unidadeRegional/${idUnidadeRegional}`)
          .query();
          listaEquipamentos = resEquipamentos.data;
        }
        catch(err) {
          listaEquipamentos = [];
          console.log("Erro ao pegar equipamentos utilizando unidade regional do relatorio", err);
        }
      }

      if(utilizarUnidadeRegionalDoRelatorio && !allValues.equipamentosMedicao)
        await getEquipamentos(allValues.idUnidadeRegional);
      else if(utilizarUnidadeRegionalDoRelatorio && allValues.equipamentosMedicao && allValues.equipamentosMedicao.length)
        listaEquipamentos = allValues.equipamentosMedicao;

      if (value && value.length >= 8) {
        return createServiceFor("/relatoriosFluviometricos")
          .customURL("GET", `paraEstacao/${value}`)
          .then(async (res) => {
            const data = res.data;

            if(!utilizarUnidadeRegionalDoRelatorio)
              await getEquipamentos(data.idUnidadeRegional);

            let dadosASubstituir = {
              codigoEstacao: value,
              estacaoSelecionada: data,
              nomeEstacao: data.nome,
              baciaEstacao: data.bacia,
              rioEstacao: data.rio,
              roteiroEstacao: data.roteiro,
              tipoEstacao: data.tipo,
              equipamentosMedicao: listaEquipamentos,
            };

            dadosEstacao = data;

            if(data.tipo.toUpperCase().includes('D')){
              tipoDadoEstacao = true
            }

          if(allValues.codigoEstacaoDigitado != allValues.codigoEstacao){
            let tipo = data.tipo.toUpperCase();
            if(tipo.includes('D')){
              tipoDadoEstacao = true
            }
            else{
              tipoDadoEstacao = false
            }
          }

            if(allValues.update){
              dadosASubstituir.tipoEstacao = allValues.tipo;

              if(allValues.codigoEstacaoDigitado != allValues.codigoEstacao){

                let tipo = data.tipo.toUpperCase();
                dadosASubstituir.tipoEstacao = tipo;

                dadosASubstituir.tipoEstacao = data.tipo;

                if(!tipo.includes('S'))
                  dadosASubstituir.descargaSolidaMedicao = null;

                if(!tipo.includes('D'))
                  dadosASubstituir.descargaLiquidaMedicao = null;

                if(!tipo.includes('Q'))
                  dadosASubstituir.qualidadeAguaMedicao = null;
              }

            }
            else {
              let tipo = data.tipo.toUpperCase();

              if(!tipo.includes('S'))
                dadosASubstituir.descargaSolidaMedicao = null;

              if(!tipo.includes('D'))
                dadosASubstituir.descargaLiquidaMedicao = null;

              if(!tipo.includes('Q'))
                dadosASubstituir.qualidadeAguaMedicao = null;
            }

            // Se a estação mudou ou é um registro novo.
            if ("undefined" === typeof allValues.id || (allValues.id && value !== allValues.codigoEstacao)) {
              dadosASubstituir["observador"] = data.observador;
            }

            return dadosASubstituir;
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              estacaoSelecionada: null,
              codigoEstacao: "",
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              tipoEstacao: "",
              observador: "",
              equipamentosMedicao: utilizarUnidadeRegionalDoRelatorio ? listaEquipamentos : []
            };
          });
      }else{
        return {
          estacaoSelecionada: null,
          codigoEstacao: "",
          nomeEstacao: "",
          baciaEstacao: "",
          rioEstacao: "",
          roteiroEstacao: "",
          tipoEstacao: "",
          observador: "",
          equipamentosMedicao: utilizarUnidadeRegionalDoRelatorio ? listaEquipamentos : []
        };
      }
    },
  },
  {
    field: "ordensServico",
    updates: {
      idsOrdemServico: (value) => value.map((e) => e.id),
    },
  },
  {
    field: /nivelamentos/,
    updates: (value, name, allValues) => {
      const nivelamentos = allValues.nivelamentos;

      const regex = /nivelamentos\[(\d+)\]\.(\w+)/;
      const resultados = regex.exec(name);

      const mudancas = {};

      if (nivelamentos) {
        if (resultados && resultados.length > 1) {
          const idx = parseInt(resultados[1]);
          const prop = resultados[2];

          const valorReAtual = parseInt(nivelamentos[idx].re || 0);
          const valorVanteAtual = parseInt(nivelamentos[idx].vante || 0);
          const valorPlanoAtual = parseInt(nivelamentos[idx].plano || 0);

          if (0 !== valorReAtual) {
            // Quando preenchemos a ré, o valor do vante deve ser 0
            mudancas[`nivelamentos[${idx}].vante`] = 0;

            // A regra pra primeira linha e diferente'
            if (0 !== idx) {
              mudancas[`nivelamentos[${idx}].cota`] =
                valorPlanoAtual - valorReAtual;

              let cotaDaLinha = document.getElementsByName(
                `nivelamentos[${idx}].cota`
              );
              if (cotaDaLinha && cotaDaLinha.length > 0) {
                cotaDaLinha[0].classList.add("hide-text");
              }
            }
          } else {
            let cotaDaLinha = document.getElementsByName(
              `nivelamentos[${idx}].cota`
            );
            if(cotaDaLinha.length > 0) {
              cotaDaLinha[0].classList.remove("hide-text");
            }
          }
          if (0 !== valorVanteAtual) {
            // Quando preenchemos o vante, o valor da ré deve ser 0
            mudancas[`nivelamentos[${idx}].re`] = 0;

            // A regra pra primeira linha e diferente
            if (0 !== idx) {
              mudancas[`nivelamentos[${idx}].cota`] =
                valorPlanoAtual - valorVanteAtual;
            }
          }

          const valorRefAtual = parseInt(nivelamentos[idx].referencia || 0);
          const valorCotaAtual = parseInt(nivelamentos[idx].cota || 0);
          if (!nivelamentos[idx].referencia || valorRefAtual === 0) {
            mudancas[`nivelamentos[${idx}].correcao`] = 0;
          } else {
            mudancas[`nivelamentos[${idx}].correcao`] =
              valorRefAtual - valorCotaAtual;
          }

          if (idx > 0) {
            const valorCotaAnterior = parseInt(nivelamentos[idx - 1].cota || 0);

            if (valorReAtual !== 0) {
              mudancas[`nivelamentos[${idx}].plano`] =
                valorReAtual + valorCotaAnterior;
            }

            const valorPlanoAnterior = parseInt(
              nivelamentos[idx - 1].plano || 0
            );

            if (valorPlanoAtual === 0) {
              mudancas[`nivelamentos[${idx}].plano`] = valorPlanoAnterior;
            }
          } else {
            mudancas[`nivelamentos[${idx}].plano`] =
              valorReAtual + valorCotaAtual;
          }

          // Copiamos o valor do plano atual para todas as linhas subsequentes
          if ("plano" === prop) {
            for (let i = idx; i < nivelamentos.length; i++) {
              mudancas[`nivelamentos[${i}].plano`] = valorPlanoAtual;
            }
          }
        }
      }
      return mudancas;
    },
  },
  {
    field: "estacaoSelecionada",
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        return {
          codigoEstacaoDigitado: value.codigo,
        };
      }

      return {};
    },
  },
  {
    field: "equipamentoMedicao",
    updates: (value, name, allValues, prevValues) => {
      return value ? {
        _equipamentoMedicaoModelo: value.modelo,
        _equipamentoMedicaoMarca: value.marca
      } : {}
    },
  },
  {
    field: "descargaLiquidaMedicao",
    updates: (value, name, allValues, prevValues) => {
      if(value === "NAO"){
        return {
          hashesNovosArquivosMDL: null,
          tipoMedicao: undefined,
        }
      }
      return {}

    },
  },
  {
    field: "descargaSolidaMedicao",
    updates: (value, name, allValues, prevValues) => {
      if(value === "NAO"){
        return {
          hashesNovosArquivosMDS: null
        }
      }
      return {}

    },
  },
  {
    field: "tipoMedicao",
    updates: (value, name, allValues, prevValues) => {
      const medicaoRioSecoOuCortado = [MEDICAO_RIO_SECO, MEDICAO_RIO_CORTADO]
        .map((tipoMedicao) => tipoMedicao.serializedName)
        .includes(value);

      if(medicaoRioSecoOuCortado){
        return {
          vazaoMedicao: 0,
        }
      } return {
        vazaoMedicao: allValues.vazaoMedicao
      }


    },
  },
  {
    field: "levantamentoMedicao",
    updates: (value, name, allValues, prevValues) => {
      if(value === "NAO"){
        return {
          hashesNovosArquivosLST: null
        }
      }
      return {}

    },
  },
  {
    field: "substituicaoObservadorHistorico",
    updates: (value, name, allValues, prevValues) => {
      if (value === "NAO"){
        return {
          dataSubstituicaoObservadorHistorico: null
        }
      }
      return {}
    }
  },

  {
    field: "houvePagamentoObservador",
    updates: (value, name, allValues, prevValues) => {
      if (value === "NAO"){
        return {
          tipoPagamentoHistorico: null,
          inicioPagamentoHistorico: null,
          fimPagamentoHistorico: null
        }
      }
      return {}
    }
  },
  {
    field: "reguasNaoAplica",
    updates: (value, name, allValues, prevValues) => {
      if (value){
        return {
          estabilidadeReguas: null,
          conservacaoReguas: null,
          lancesVisiveisReguas: null
        }
      }
      return {}
    }
  },
  {
    field: "semObservador",
    updates: (value, name, allValues, prevValues) => {
      if (value){
        return {
          avaliacaoObservadorHistorico: null,
          substituicaoObservadorHistorico: null,
          houvePagamentoObservador: null,
          recolhimentoBoletimHistorico: null,
          dataCadernetasHistorico: null,
          dataEnvelopesHistorico: null,
          dataSubstituicaoObservadorHistorico: null,
          tipoPagamentoHistorico: null,
          inicioPagamentoHistorico: null,
          fimPagamentoHistorico: null,
          periodoRecBoletimHistorico: null,
          nomeObservador: null,
          observador: null,
        }
      }
      return {}
    }
  }


);

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) => e.nome || e.descricao || e.codigo;
const equipamentoMedicaoLabelResolver = e => e.numero === "Indeterminado" ? e.modelo : e.numero;

// RN
const TipoEstacaoEh = ({ tipos = [], children }) => {
  return (
    <Field name="tipoEstacao" subscription={{ value: true }}>
      {({ input: { value } }) => {
        const estacaoEhTipOCerto =
          !!value && "" !== value && tipos.some((tipo) => value.includes(tipo));
        return children(estacaoEhTipOCerto);
      }}
    </Field>
  );
};


const CampoRelacionadoADescargaLiquida = ({ children }) => (
  <TipoEstacaoEh tipos={["D"]}>
    {(tipoCerto) => (
      <Field name="descargaLiquidaMedicao" subscription={{ value: true }}>
        {({ input: { value } }) => children(!!tipoCerto && "SIM" === value)}
      </Field>
    )}
  </TipoEstacaoEh>
);

const CampoRelacionadoAHouvePagamentoObservador = ({ children }) => (
  <Field name="houvePagamentoObservador" subscription={{ value: true }}>
    {({ input: { value } }) =>  children("SIM" === value)}
  </Field>
);


class PaginaFormRelatoriosFluviometricos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipo : "",
      dados:dadosEstacao,
      emEdicao: false,
      fotosRemovidas: []
    };
  }


  componentDidMount() {

    const promises = [
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/tiposOrdemServico/fluviometricos").query(),
      createServiceFor("/processosMedicao").query(),
      createServiceFor("/equipamentos/descargaLiquida/simples").query(), // Equipamentos de medição (de descarga líquida)
      createServiceFor("/estacoes/simples").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/relatoriosFluviometricos").get(
          this.props.match.params.id
        )
      );

      this.setState({
        emEdicao: true
      })
    }


    let estacao;
    this.state.tipo = tipoDadoEstacao

    this.state.dados = dadosEstacao
    const promise = Promise.all(promises)
      .then(async ([
        usuarios,
        tiposOrdemServico,
        processosMedicao,
        equipamentosMedicao,
        estacoes,
        relatorio = {}
      ]) =>{
          if(this.props.match.params.id) {
            try{
              const codigoEstacao = relatorio.data.codigoEstacao;

              const res = await createServiceFor("/relatoriosFluviometricos")
                .customURL("GET", `paraEstacao/${codigoEstacao}`);

               estacao = res.data;
               this.setState({dados: estacao})

              if(!estacoes.data.find((e) => e.id === estacao.id)){
                estacoes.data.push(estacao);
                estacoes.data = estacoes.data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
              }
            }catch(err){}

          }

          const reguasNaoAplica = (!relatorio?.data?.estabilidadeReguas || !relatorio?.data?.conservacaoReguas);
          const semObservador = (!relatorio?.data?.avaliacaoObservadorHistorico || !relatorio?.data?.substituicaoObservadorHistorico);

          if(relatorio.data) {
            this.setState({relatorio: relatorio.data || null })
          }

          return {
          usuarios: usuarios.data,
          tiposOrdemServico: tiposOrdemServico.data,
          processosMedicao: processosMedicao.data,
          equipamentosMedicao: equipamentosMedicao.data,
          estacoes: estacoes.data,
          estacoesTipoF: estacoes.data.filter(estacao => estacao.tipo.toUpperCase().includes('F')),
          initialValues: relatorio.data
            ? {
                ...relatorio.data,
                reguasNaoAplica,
                semObservador,
                habilitarEstacoesSemF: !relatorio.data.tipo.toUpperCase().includes('F'),
                ordensServico: tiposOrdemServico.data.filter((tipo) =>
                  (relatorio.data.idsOrdemServico || []).includes(tipo.id)
                ),
                codigoEstacaoDigitado: relatorio.data.codigoEstacao,
                observador: relatorio.data.observadorOriginal,
                // Tentar selecionar equipamentoMedicao caso haja descarga líquida
                equipamentoMedicao: relatorio.data.descargaLiquidaMedicao === "SIM"
                  ? equipamentosMedicao.data.filter(e => e.id === relatorio.data.idEquipamentoMedicao)[0]
                  : relatorio.data.idEquipamentoMedicao,
                vazaoMedicao : relatorio.data.vazaoMedicao === 0 ? "0" : relatorio.data.vazaoMedicao
              }
            : {
              reguasNaoAplica,
              habilitarEstacoesSemF: false,
              semObservador: false,
            },
        }}

      )

      .catch((e) => {
        console.log(e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  componentWillUnmount() {
    this.props.limparArquivos();
  }

  submitForm(values) {

    /*
    const fotos = [...(values.hashesNovasFotos ?? []), ...(values.idsFotosExistentes ?? [])];
    const totalFotos = fotos.filter((idFoto) => !this.state.fotosRemovidas.find(removida => removida == idFoto));
    if(totalFotos.length < 4){
      store.dispatch(
        showGlobalNotification({
          message: "Fotos obrigatórias não inseridas",
          forcarExibicao: true,
        })
      );
      return;
    }
    */

    // Remover propriedades não utilizadas no backend
    delete values._equipamentoMedicaoModelo;
    delete values._equipamentoMedicaoMarca;

    // Enviar id do equipamento caso haja algum selecionado
    if (values.equipamentoMedicao) {
      values.idEquipamentoMedicao = values.equipamentoMedicao.id;
      delete values.equipamentoMedicao;
    }

    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/relatoriosFluviometricos")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  exibirTab(valueInput) {
    return valueInput !== undefined && valueInput === "SIM";
}

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {


      form.getRegisteredFields().forEach((field, i) => {

        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;

          console.log('Error')

          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.relatorio && this.state.dados
          ? `
            ${
              this.state?.relatorio?.id
                ? `<div class="text">Relatório fluviométrico</div> - <div class="text">ID: </div>${this.state.relatorio.id}`
                : ''
            }
            ${
              this.state?.dados?.nome
                ? ` - <div class="text">Estação: </div>${this.state.dados.nome}`
                : ''
            }
            ${
              this.state?.relatorio?.campanhaVisita
                ? ` - <div class="text">Campanha: </div>${this.state?.relatorio?.campanhaVisita}`
                : ''
            }
            ${
              this.state?.relatorio?.roteiro
                ? ` - <div class="text">Roteiro: </div>${this.state?.relatorio?.roteiro}`
                : ''
            }
            ${
              this.props?.location?.state?.data?.unidadeRegional
                ? ` - ${this.props.location.state.data.unidadeRegional}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        dados ={this.state.dados}
        tipoRelatorio="FLUVIOMETRICO"
        baseTitle="Relatórios fluviométricos"
        baseUrl="/sgih/app/relatoriosFluviometricos"
        history={this.props.history}
        shouldAddReportFlowButton={true}
        shouldAddRefreshButton={true}
        match={this.props.match}
        ehRelatorio={true}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={(event) => this.submitForm(event)}
                decorators={[calculatedStationDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push, setValue },
                  },
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="codigoEstacao"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="equipamentosMedicao"
                      />
                      <Tab.Container
                        className="pb-2 esconder-abas-desativadas"
                        useEvent={true}
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history,
                          true
                        )}
                        // key={form.getState().values.descargaSolidaMedicao || form.getState().values.levantamentoMedicao || form.getState().values.descargaLiquidaMedicao}
                      >
                        <Tab title="Dados da estação">
                          {estaEditando && <Container fluid>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                    component={TextField}
                                    name="origem"
                                    label="Origem relatório"
                                    readOnly
                                />
                              </Col>
                            </Row>
                          </Container>}

                          <FormSectionWrapper title="Informações gerais">
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="habilitarEstacoesSemF" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={TextField}
                                      name="codigoEstacaoDigitado"
                                      required
                                      label="Código"
                                      disabled={this.state.emEdicao ? true : disableAllFields}
                                      mostrarMensagemOnBlur
                                      validate={value ? undefined : tipoEstacaoDeveConter("F")}
                                      minLength={8}
                                      maxLength={8}
                                    />
                                   }}
                                </Field>

                                <p style={{ color: 'red', marginTop: 10, marginBottom: 10, fontSize: 12 }}>Caso seja necessário gerar um relatório fluviométrico para a estação que não é do tipo F (não gera boletins F), selecione a caixa abaixo:</p>

                                <Field
                                  component={CheckField}
                                  name="habilitarEstacoesSemF"
                                  type="checkbox"
                                  className="form-control"
                                  label="Habilitar estações sem o tipo F"
                                  disabled={this.state.emEdicao ? true : disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field name="habilitarEstacoesSemF" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                  return <Field
                                      component={SelectField}
                                      name="estacaoSelecionada"
                                      label="Estação"
                                      elements={value ? data.estacoes : data.estacoesTipoF}
                                      buscavel
                                      callbackWithOriginalValue
                                      disabled={this.state.emEdicao ? true : disableAllFields}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                    />
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="baciaEstacao"
                                  label="Bacia"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="rioEstacao"
                                  label="Rio"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "roteiro" : "roteiroEstacao"}
                                  label="Roteiro"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name={"tipoEstacao"}
                                  label="Tipo"
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Dados da visita">
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                    name="idsMembrosEquipe"
                                    component={JqxDropDownListAdapter}
                                    source={data.usuarios}
                                    label="Equipe" required
                                    checkboxes={true}
                                    displayMember="nome"
                                    valueMember="id"
                                    placeHolder="Selecione a equipe..."
                                    searchMode="startswithignorecase"
                                    enableSelection={true}
                                    enableHover={true}
                                    filterable={true}
                                    incrementalSearch={true}
                                    animationType="none"
                                    closeDelay={0}
                                    filterPlaceHolder=" Busque"
                                    disabled={disableAllFields}
                                    validate={requiredWithNotification("Equipe")}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="tipoVisita"
                                  label="Tipo de visita"
                                  elements={tiposVisita}
                                  disabled={disableAllFields}
                                  required
                                  defaultValue={
                                    OPERACAO_DE_ROTEIRO.serializedName
                                  }
                                  validate={requiredWithNotification(
                                    "Tipo de visita"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="dataVisita"
                                  label="Data"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification("Data")}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="horaVisita"
                                  label="Hora"
                                  datePickerType="hour"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification("Hora")}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="campanhaVisita"
                                  label="Campanha"
                                  datePickerType="yearMonth"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Campanha"
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Réguas e R.N.">
                          <FormSectionWrapper title="Situação encontrada réguas">

                            <Field name="habilitarEstacoesSemF" subscription={{ value: true }}>
                              {({ input: { value } }) => {

                                return value ? <Field
                                  component={CheckField}
                                  name="reguasNaoAplica"
                                  type="checkbox"
                                  className="form-control"
                                  label="Réguas - Não se aplica"
                                disabled={disableAllFields}
                                /> : <></>;
                              }}
                            </Field>

                            <Row>
                              <Col className="mt-3" md={6}>
                                <Field name="reguasNaoAplica" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={CheckSetField}
                                      name="estabilidadeReguas"
                                      label="Estabilidade"
                                      disabled={value ? true : disableAllFields}
                                      elements={goodRegularOrBad}
                                      required={!value}
                                      validate={requiredWithNotificationCheckField(
                                        "Estabilidade", "reguasNaoAplica"
                                      )}
                                    />
                                  }}
                                </Field>
                              </Col>

                              <Col className="mt-3" md={6}>
                                <Field name="reguasNaoAplica" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                  return <Field
                                    component={CheckSetField}
                                    name="conservacaoReguas"
                                    label="Conservação"
                                    disabled={value ? true : disableAllFields}
                                    elements={goodRegularOrBad}
                                    required={!value}
                                    validate={requiredWithNotificationCheckField(
                                      "Conservação", "reguasNaoAplica"
                                    )}
                                  />
                                  }}
                                </Field>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="cotaReguas"
                                  label="Cota (cm)"
                                  disabled={disableAllFields}
                                  maxlength={10}
                                  required
                                  forcarMaiusculas
                                  validate={numberOrRCRSWithNumbers}
                                />
                              </Col>

                              <Col className="mt-3" md={4}>
                                <Field
                                  component={DateTimeField}
                                  name="horaVisitaReguas"
                                  label="Hora inicial da medição"
                                  datePickerType="hour"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Hora inicial da medição"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field name="reguasNaoAplica" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={TextField}
                                      name="lancesVisiveisReguas"
                                      label="Lances visíveis (cm)"
                                      disabled={value ? true : disableAllFields}
                                      required={!value}
                                      validate={requiredWithNotificationCheckField(
                                        "Lances visíveis (cm)", "reguasNaoAplica"
                                      )}
                                      maxLength={50}
                                    />
                                  }}
                                </Field>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Situação encontrada R.N.">
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={CheckSetField}
                                  name="conservacaoReferenciaNivel"
                                  label="Conservação R.N."
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Conservação R.N."
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        {tipoDadoEstacao ?

                        <Tab title="Seção de medição"  >
                          <FormSectionWrapper title="Situação encontrada">
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="acessoSecaoMedicao"
                                  label="Acesso"
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification("Acesso")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="pontoInicialSecaoMedicao"
                                  label="Ponto inicial - PI"
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Ponto inicial - PI"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="obstrucaoSecaoMedicao"
                                  label="Obstrução na seção"
                                  elements={yesOrNo}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Obstrução na seção"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="limpezaSecaoMedicao"
                                  label="Limpeza"
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification("Limpeza")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="pontoFinalSecaoMedicao"
                                  label="Ponto final - PF"
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Ponto final - PF"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={SelectField}
                                  name="idProcessoMedicaoSecaoMedicao"
                                  label="Processo de medição"
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  elements={data.processosMedicao}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Processo de medição"
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>

                        : <Tab title="Seção de medição" visible={false} >
                        <FormSectionWrapper title="Situação encontrada">
                          <Row>
                            <Col className="mt-3" md={4}>
                            <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                              <Field
                                component={CheckSetField}
                                name="acessoSecaoMedicao"
                                label="Acesso"
                                elements={goodRegularOrBad}
                                required={tipoCerto}
                                key={tipoCerto}
                                disable={  !tipoCerto}
                                validate={
                                  tipoCerto
                                    ? requiredWithNotification(
                                      "Acesso"
                                      )
                                    : undefined
                                }
                              />
                               </>
                                  )}
                              </TipoEstacaoEh>
                            </Col>
                            <Col className="mt-3" md={4}>
                            <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                              <Field
                                component={CheckSetField}
                                name="pontoInicialSecaoMedicao"
                                label="Ponto inicial - PI"
                                elements={goodRegularOrBad}

                                required={tipoCerto}
                                key={tipoCerto}
                                disable={  !tipoCerto}
                                validate={
                                  tipoCerto
                                    ? requiredWithNotification(
                                      "Ponto inicial - PI"
                                      )
                                    : undefined
                                }
                              />
                              </>
                                  )}
                              </TipoEstacaoEh>
                            </Col>
                            <Col className="mt-3" md={4}>
                            <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                              <Field
                                component={CheckSetField}
                                name="obstrucaoSecaoMedicao"
                                label="Obstrução na seção"
                                elements={yesOrNo}
                                required={tipoCerto}
                                key={tipoCerto}
                                disable={  !tipoCerto}
                                validate={
                                  tipoCerto
                                    ? requiredWithNotification(
                                      "Obstrução na seção"
                                      )
                                    : undefined
                                }
                              />
                                </>
                                  )}
                              </TipoEstacaoEh>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" md={4}>
                            <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                              <Field
                                component={CheckSetField}
                                name="limpezaSecaoMedicao"
                                label="Limpeza"
                                elements={goodRegularOrBad}
                                required={tipoCerto}
                                key={tipoCerto}
                                disable={  !tipoCerto}
                                validate={
                                  tipoCerto
                                    ? requiredWithNotification(
                                      "Limpeza"
                                      )
                                    : undefined
                                }
                              />
                              </>
                                  )}
                              </TipoEstacaoEh>
                            </Col>
                            <Col className="mt-3" md={4}>
                            <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                              <Field
                                component={CheckSetField}
                                name="pontoFinalSecaoMedicao"
                                label="Ponto final - PF"
                                elements={goodRegularOrBad}
                                disabled={disableAllFields}
                                required={tipoCerto}
                                key={tipoCerto}
                                disable={  !tipoCerto}
                                validate={
                                  tipoCerto
                                    ? requiredWithNotification(
                                      "Ponto final - PF"
                                      )
                                    : undefined
                                }
                              />
                              </>
                                  )}
                              </TipoEstacaoEh>
                            </Col>
                            <Col className="mt-3" md={4}>
                            <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                              <Field
                                component={SelectField}
                                name="idProcessoMedicaoSecaoMedicao"
                                label="Processo de medição"
                                valueResolver={identifierValueResolver}
                                labelResolver={descriptionLabelResolver}
                                elements={data.processosMedicao}

                                required={tipoCerto}
                                key={tipoCerto}
                                disable={  !tipoCerto}
                                validate={
                                  tipoCerto
                                    ? requiredWithNotification(
                                      "Processo de medição"
                                      )
                                    : undefined
                                }

                              />
                               </>
                                  )}
                              </TipoEstacaoEh>
                            </Col>
                          </Row>
                        </FormSectionWrapper>
                      </Tab>}
                        {tipoDadoEstacao ?
                        <Tab title="Medições">
                        <FormSectionWrapper title="Informações da medição">
                          <Row>
                            <Col className="mt-3" md={4}>
                              <TipoEstacaoEh tipos={["D"]}>
                                {(tipoCerto) => (
                                  <>
                                    <Field
                                      component={CheckSetField}
                                      name="descargaLiquidaMedicao"
                                      label="Descarga líquida"
                                      elements={yesOrNo}
                                      disabled={
                                        disableAllFields || !tipoCerto
                                      }
                                      key={tipoCerto}
                                      required={tipoCerto}
                                      validate={
                                        tipoCerto
                                          ? requiredWithNotification(
                                              "Descarga líquida"
                                            )
                                          : undefined
                                      }
                                    />
                                  </>
                                )}
                              </TipoEstacaoEh>
                            </Col>
                            <Col className="mt-3" md={4}>
                              <CampoRelacionadoADescargaLiquida>
                                {(habilitado) => (
                                  <Field
                                    component={SelectField}
                                    name="localMedicao"
                                    label="Local da medição"
                                    elements={locaisMedicao}
                                    disabled={disableAllFields || !habilitado}
                                    key={habilitado}
                                    required={habilitado}
                                    validate={
                                      habilitado
                                        ? requiredWithNotification(
                                            "Local da medição"
                                          )
                                        : undefined
                                    }
                                  />
                                )}
                              </CampoRelacionadoADescargaLiquida>
                            </Col>
                            <Col className="mt-3" md={4}>
                              <Field name="equipamentosMedicao" subscription={{ value: true }}>
                              {({ input: { value } }) => {

                                let lista = value ? value : [];

                                return <CampoRelacionadoADescargaLiquida>
                                {(habilitado) => (
                                  <Field
                                    component={SelectField}
                                    name="equipamentoMedicao"
                                    label="NR. do equipamento"
                                    buscavel
                                    valueResolver={identifierValueResolver}
                                    labelResolver={equipamentoMedicaoLabelResolver}
                                    elements={lista}
                                    callbackWithOriginalValue
                                    disabled={disableAllFields || !habilitado}
                                    key={habilitado}
                                    required={habilitado}
                                    validate={
                                      habilitado
                                        ? requiredWithNotification(
                                            "NR. do equipamento"
                                          )
                                        : undefined
                                    }
                                  />
                                )}
                              </CampoRelacionadoADescargaLiquida>
                              }}
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" md={4}>
                              <TipoEstacaoEh tipos={["S"]}>
                                {(tipoCerto) => (
                                  <>
                                    <Field
                                      component={CheckSetField}
                                      name="descargaSolidaMedicao"
                                      label="Descarga sólida"
                                      elements={yesOrNo}
                                      disabled={
                                        disableAllFields || !tipoCerto
                                      }
                                      key={tipoCerto}
                                      required={tipoCerto}
                                      validate={
                                        tipoCerto
                                          ? requiredWithNotification(
                                              "Descarga sólida"
                                            )
                                          : undefined
                                      }
                                    />
                                  </>
                                )}
                              </TipoEstacaoEh>
                            </Col>
                            <CampoRelacionadoADescargaLiquida>
                              {(habilitado) => (
                                <Field
                                  name="localMedicao"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const localMedicaoForaDaSecao =
                                      habilitado && "FORA_SECAO" === value;
                                    return (
                                      <>
                                        <Col className="mt-3" md={4}>
                                          <Field
                                            component={NumberField}
                                            max={99999999.99}
                                            name="distanciaSecaoMedicao"
                                            decimaisAposvirgula={2}
                                            label="Distância da seção (m)"
                                            disabled={
                                              disableAllFields ||
                                              !localMedicaoForaDaSecao
                                            }
                                            key={localMedicaoForaDaSecao}
                                            required={localMedicaoForaDaSecao}
                                            validate={
                                              localMedicaoForaDaSecao
                                                ? requiredWithNotification(
                                                    "Distância da seção (m)"
                                                  )
                                                : undefined
                                            }
                                          />
                                        </Col>
                                      </>
                                    );
                                  }}
                                </Field>
                              )}
                            </CampoRelacionadoADescargaLiquida>
                            <Col className="mt-3" md={4}>
                              <Field
                                component={TextField}
                                label="Marca do equipamento"
                                name="_equipamentoMedicaoMarca"
                                readOnly={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" md={4}>
                              <TipoEstacaoEh tipos={["D"]}>
                                {(tipoCerto) => (
                                  <>
                                    <Field
                                      component={CheckSetField}
                                      name="levantamentoMedicao"
                                      label="Levantamento de seção"
                                      required={tipoCerto}
                                      elements={yesOrNo}
                                      disabled={
                                        disableAllFields || !tipoCerto
                                      }
                                      key={tipoCerto}
                                      validate={
                                        tipoCerto
                                          ? requiredWithNotification(
                                              "Levantamento de seção"
                                            )
                                          : undefined
                                      }
                                    />
                                  </>
                                )}
                              </TipoEstacaoEh>
                            </Col>
                            {/* <Col className="mt-3" md={4}>
                              <TipoEstacaoEh tipos={["Q"]}>
                                {(tipoCerto) => (
                                  <>
                                    <Field
                                      component={CheckSetField}
                                      name="qualidadeAguaMedicao"
                                      label="Qualidade de água"
                                      elements={yesOrNo}
                                      disabled={
                                        disableAllFields || !tipoCerto
                                      }
                                      key={tipoCerto}
                                      required={tipoCerto}
                                      validate={
                                        tipoCerto
                                          ? requiredWithNotification(
                                              "Qualidade de água"
                                            )
                                          : undefined
                                      }
                                    />
                                  </>
                                )}
                              </TipoEstacaoEh>
                            </Col> */}

                            <CampoRelacionadoADescargaLiquida>
                              {(habilitado) => (
                                <Field
                                  name="localMedicao"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const localMedicaoForaDaSecao =
                                      habilitado && "FORA_SECAO" === value;
                                    return (
                                      <Col className="mt-3" md={4}>
                                        <Field
                                          component={SelectField}
                                          name="posicaoMedicao"
                                          label="Posição da medição"
                                          elements={montanteOuJusante}
                                          disabled={
                                            disableAllFields ||
                                            !localMedicaoForaDaSecao
                                          }
                                          key={localMedicaoForaDaSecao}
                                          required={localMedicaoForaDaSecao}
                                          validate={
                                            localMedicaoForaDaSecao
                                              ? requiredWithNotification(
                                                  "Posição da medição"
                                                )
                                              : undefined
                                          }
                                        />
                                      </Col>
                                    );
                                  }}
                                </Field>
                              )}
                            </CampoRelacionadoADescargaLiquida>
                            <Col className="mt-3" md={4}>
                              <Field
                                component={TextField}
                                label="Modelo do equipamento"
                                name="_equipamentoMedicaoModelo"
                                readOnly={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4} xs={0}></Col>
                            <CampoRelacionadoADescargaLiquida>
                              {(descargaLiquida) => (
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={SelectField}
                                    name="tipoMedicao"
                                    label="Tipo de medição"
                                    elements={tiposMedicao}
                                    disabled={
                                      disableAllFields
                                      || !descargaLiquida
                                    }
                                    key={descargaLiquida}
                                    required={descargaLiquida}
                                    validate={
                                      descargaLiquida
                                        ? requiredWithNotification(
                                            "Tipo de medição"
                                          )
                                        : undefined
                                    }
                                  />
                                </Col>
                              )}
                            </CampoRelacionadoADescargaLiquida>
                            <CampoRelacionadoADescargaLiquida>
                                {(descargaLiquida) => (
                                  <Field
                                    name="tipoMedicao"
                                    subscription={{ value: true }}
                                  >
                                    {({ input: { value } }) => {
                                      const tipoMedicao = value;

                                      const tipoMedicaoRioSecoOuCortado = [MEDICAO_RIO_SECO, MEDICAO_RIO_CORTADO]
                                        .map((tipo) => tipo.serializedName)
                                        .includes(tipoMedicao);

                                      const habilitarCampoVazaoMedicao = descargaLiquida && !tipoMedicaoRioSecoOuCortado;

                                      return (
                                        <Col className="mt-3" md={4}>
                                          <Field
                                            component={NumberField}
                                            max={9999999999.9999}
                                            min={0}
                                            decimaisAposvirgula={4}
                                            name="vazaoMedicao"
                                            label="Vazão (m³/s)"
                                            disabled={
                                              disableAllFields
                                              || !habilitarCampoVazaoMedicao
                                            }
                                            key={habilitarCampoVazaoMedicao}
                                            required={habilitarCampoVazaoMedicao}
                                            validate={
                                              habilitarCampoVazaoMedicao
                                                ? requiredWithNotification(
                                                    "Vazão (m³/s)"
                                                  )
                                                : undefined
                                            }
                                          />
                                        </Col>
                                      );
                                    }}
                                  </Field>
                                )}
                              </CampoRelacionadoADescargaLiquida>
                          </Row>
                        </FormSectionWrapper>
                      </Tab>
                      :  <Tab title="Medições" visible={false}>
                          <FormSectionWrapper title="Informações da medição">
                            <Row>
                              <Col className="mt-3" md={4}>
                                <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                                      <Field
                                        component={CheckSetField}
                                        name="descargaLiquidaMedicao"
                                        label="Descarga líquida"
                                        elements={yesOrNo}
                                        disabled={
                                          disableAllFields || !tipoCerto
                                        }
                                        key={tipoCerto}
                                        required={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                                "Descarga líquida"
                                              )
                                            : undefined
                                        }
                                      />
                                    </>
                                  )}
                                </TipoEstacaoEh>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <CampoRelacionadoADescargaLiquida>
                                  {(habilitado) => (
                                    <Field
                                      component={SelectField}
                                      name="localMedicao"
                                      label="Local da medição"
                                      elements={locaisMedicao}
                                      disabled={disableAllFields || !habilitado}
                                      key={habilitado}
                                      required={habilitado}
                                      validate={
                                        habilitado
                                          ? requiredWithNotification(
                                              "Local da medição"
                                            )
                                          : undefined
                                      }
                                    />
                                  )}
                                </CampoRelacionadoADescargaLiquida>
                              </Col>
                              <Col className="mt-3" md={4}>
                                 <Field name="equipamentosMedicao" subscription={{ value: true }}>
                              {({ input: { value } }) => {

                                let lista = value ? value : [];

                                return <CampoRelacionadoADescargaLiquida>
                                  {(habilitado) => (
                                    <Field
                                      component={SelectField}
                                      name="equipamentoMedicao"
                                      label="NR. do equipamento"
                                      buscavel
                                      valueResolver={identifierValueResolver}
                                      labelResolver={equipamentoMedicaoLabelResolver}
                                      elements={lista}
                                      callbackWithOriginalValue
                                      disabled={disableAllFields || !habilitado}
                                      key={habilitado}
                                      required={habilitado}
                                      validate={
                                        habilitado
                                          ? requiredWithNotification(
                                              "NR. do equipamento"
                                            )
                                          : undefined
                                      }
                                    />
                                    )}
                                    </CampoRelacionadoADescargaLiquida>
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <TipoEstacaoEh tipos={["S"]}>
                                  {(tipoCerto) => (
                                    <>
                                      <Field
                                        component={CheckSetField}
                                        name="descargaSolidaMedicao"
                                        label="Descarga sólida"
                                        elements={yesOrNo}
                                        disabled={
                                          disableAllFields || !tipoCerto
                                        }
                                        key={tipoCerto}
                                        required={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                                "Descarga sólida"
                                              )
                                            : undefined
                                        }
                                      />
                                    </>
                                  )}
                                </TipoEstacaoEh>
                              </Col>
                              <CampoRelacionadoADescargaLiquida>
                                {(habilitado) => (
                                  <Field
                                    name="localMedicao"
                                    subscription={{ value: true }}
                                  >
                                    {({ input: { value } }) => {
                                      const localMedicaoForaDaSecao =
                                        habilitado && "FORA_SECAO" === value;
                                      return (
                                        <>
                                          <Col className="mt-3" md={4}>
                                            <Field
                                              component={NumberField}
                                              max={99999999.99}
                                              name="distanciaSecaoMedicao"
                                              decimaisAposvirgula={2}
                                              label="Distância da seção (m)"
                                              disabled={
                                                disableAllFields ||
                                                !localMedicaoForaDaSecao
                                              }
                                              key={localMedicaoForaDaSecao}
                                              required={localMedicaoForaDaSecao}
                                              validate={
                                                localMedicaoForaDaSecao
                                                  ? requiredWithNotification(
                                                      "Distância da seção (m)"
                                                    )
                                                  : undefined
                                              }
                                            />
                                          </Col>
                                        </>
                                      );
                                    }}
                                  </Field>
                                )}
                              </CampoRelacionadoADescargaLiquida>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  label="Marca do equipamento"
                                  name="_equipamentoMedicaoMarca"
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                            <Row>
                              {/* <Col className="mt-3" md={4}>
                                <TipoEstacaoEh tipos={["Q"]}>
                                  {(tipoCerto) => (
                                    <>
                                      <Field
                                        component={CheckSetField}
                                        name="qualidadeAguaMedicao"
                                        label="Qualidade de água"
                                        elements={yesOrNo}
                                        disabled={
                                          disableAllFields || !tipoCerto
                                        }
                                        key={tipoCerto}
                                        required={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                                "Qualidade de água"
                                              )
                                            : undefined
                                        }
                                      />
                                    </>
                                  )}
                                </TipoEstacaoEh>
                              </Col> */}

                              <CampoRelacionadoADescargaLiquida>
                                {(habilitado) => (
                                  <Field
                                    name="localMedicao"
                                    subscription={{ value: true }}
                                  >
                                    {({ input: { value } }) => {
                                      const localMedicaoForaDaSecao =
                                        habilitado && "FORA_SECAO" === value;
                                      return (
                                        <Col className="mt-3" md={4}>
                                          <Field
                                            component={SelectField}
                                            name="posicaoMedicao"
                                            label="Posição da medição"
                                            elements={montanteOuJusante}
                                            disabled={
                                              disableAllFields ||
                                              !localMedicaoForaDaSecao
                                            }
                                            key={localMedicaoForaDaSecao}
                                            required={localMedicaoForaDaSecao}
                                            validate={
                                              localMedicaoForaDaSecao
                                                ? requiredWithNotification(
                                                    "Posição da medição"
                                                  )
                                                : undefined
                                            }
                                          />
                                        </Col>
                                      );
                                    }}
                                  </Field>
                                )}
                              </CampoRelacionadoADescargaLiquida>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  label="Modelo do equipamento"
                                  name="_equipamentoMedicaoModelo"
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                            <Row>
                            <Col className="mt-3" md={4}>
                                <TipoEstacaoEh tipos={["D"]}>
                                  {(tipoCerto) => (
                                    <>
                                      <Field
                                        component={CheckSetField}
                                        name="levantamentoMedicao"
                                        label="Levantamento de seção"
                                        required={tipoCerto}
                                        elements={yesOrNo}
                                        disabled={
                                          disableAllFields || !tipoCerto
                                        }
                                        key={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                                "Levantamento de seção"
                                              )
                                            : undefined
                                        }
                                      />
                                    </>
                                  )}
                                </TipoEstacaoEh>
                              </Col>
                              <CampoRelacionadoADescargaLiquida>
                                {(descargaLiquida) => (
                                  <Col className="mt-3 offset-4" md={4}>
                                    <Field
                                      component={SelectField}
                                      name="tipoMedicao"
                                      label="Tipo de medição"
                                      elements={tiposMedicao}
                                      disabled={
                                        disableAllFields
                                        || !descargaLiquida
                                      }
                                      key={descargaLiquida}
                                      required={descargaLiquida}
                                      validate={
                                        descargaLiquida
                                          ? requiredWithNotification(
                                              "Tipo de medição"
                                            )
                                          : undefined
                                      }
                                    />
                                  </Col>
                                )}
                              </CampoRelacionadoADescargaLiquida>
                              <CampoRelacionadoADescargaLiquida>
                                {(descargaLiquida) => (
                                  <Field
                                    name="tipoMedicao"
                                    subscription={{ value: true }}
                                  >
                                    {({ input: { value } }) => {
                                      const tipoMedicao = value;

                                      const tipoMedicaoRioSecoOuCortado = [MEDICAO_RIO_SECO, MEDICAO_RIO_CORTADO]
                                        .map((tipo) => tipo.serializedName)
                                        .includes(tipoMedicao);

                                      const habilitarCampoVazaoMedicao = descargaLiquida && !tipoMedicaoRioSecoOuCortado;

                                      return (
                                        <Col className="mt-3" md={4}>
                                          <Field
                                            component={NumberField}
                                            max={9999999999.9999}
                                            min={0}
                                            decimaisAposvirgula={4}
                                            name="vazaoMedicao"
                                            label="Vazão (m³/s)"
                                            disabled={
                                              disableAllFields
                                              || !habilitarCampoVazaoMedicao
                                            }
                                            key={habilitarCampoVazaoMedicao}
                                            required={habilitarCampoVazaoMedicao}
                                            validate={
                                              habilitarCampoVazaoMedicao
                                                ? requiredWithNotification(
                                                    "Vazão (m³/s)"
                                                  )
                                                : undefined
                                            }
                                          />
                                        </Col>
                                      );
                                    }}
                                  </Field>
                                )}
                              </CampoRelacionadoADescargaLiquida>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>}

                        <Tab title="Serviços">
                          <FormSectionWrapper title="Serviços realizados">
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="limpezaEstRnsSupRegServicosRealizados"
                                  label="Limpeza da estação, rn's, suportes e réguas"
                                  elements={yesOrNo}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Limpeza da estação, rn's, suportes e réguas"
                                  )}
                                  required
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextAreaField}
                                  name="observacoesGeraisServicosRealizados"
                                  disabled={disableAllFields}
                                  maxLength={500}
                                  label="Observações gerais"
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="O.S.">
                          <FormSectionWrapper title="Ordens de serviço para próxima viagem">
                            <Row>
                              <Col
                                className="mt-3"
                                md={disableAllFields ? 12 : 10}
                              >
                                <Field
                                  component={SelectField}
                                  name="ordemServicoSelecionada"
                                  callbackWithOriginalValue={true}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  disabled={disableAllFields}
                                  elements={data.tiposOrdemServico}
                                />
                              </Col>
                              {!disableAllFields && (
                                <Col className="mt-3" md={2}>
                                  <BotaoPadrao
                                    onClick={() => {
                                      const workOrder = form.getState().values
                                        .ordemServicoSelecionada;
                                      if (workOrder) {
                                        push("ordensServico", workOrder);
                                      }
                                    }}
                                  >
                                    Adicionar
                                  </BotaoPadrao>
                                </Col>
                              )}
                            </Row>
                            <FieldArray
                              name="ordensServico"
                              validate={atLeastOneElement(
                                'Caso não exista planejamento para a próxima visita, selecione a opção "Estação em perfeitas condições de operação"'
                              )}
                            >

                              {({ fields }) => {
                                const necessarioDetalharOutrasOpcoes = (
                                  fields.value || []
                                ).some((os) => 22 === os.id);
                                return (
                                  <div>
                                    <Error name="ordensServico" />
                                    {fields.map((name, index) => {
                                      return (
                                        <Row className="mt-3" key={index}>
                                          <Col>
                                            <Field
                                              component={TextField}
                                              label=""
                                              name={`ordensServico[${index}].descricao`}
                                              readOnly={true}
                                            />
                                          </Col>
                                          <Col md="auto">
                                            <BotaoIconeExclusao
                                              onClick={() =>
                                                fields.remove(index)
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                    {necessarioDetalharOutrasOpcoes && (
                                      <div className="mt-3">
                                        <Field
                                          component={TextAreaField}
                                          name="descOSOutrasOpcoes"
                                          label="Outras descrições"
                                          rows={4}
                                          disabled={disableAllFields}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Observações">
                          <FormSectionWrapper title="Observações gerais">
                            <Row>
                              <Col className="mt-3" md={6}>
                                <Field
                                  component={CheckSetField}
                                  name="realizacaoServico"
                                  label="Realização da ordem de serviço"
                                  elements={totalParcialOuNao}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Realização da ordem de serviço"
                                  )}
                                  required
                                />
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Fields
                                  subscription={{ value: true }}
                                  names={[
                                    "substituicaoObservadorHistorico",
                                    "avaliacaoObservadorHistorico",
                                  ]}
                                >
                                  {({
                                    valores: {
                                      substituicaoObservadorHistorico,
                                      avaliacaoObservadorHistorico,
                                    },
                                  }) => {
                                    const isRequired =
                                      "SIM" === substituicaoObservadorHistorico ||
                                      "RUIM" === avaliacaoObservadorHistorico;
                                    return (
                                      <Field
                                        component={TextAreaField}
                                        maxLength={500}
                                        name="observacoesGerais"
                                        label="Observações gerais"
                                        key={isRequired}
                                        disabled={disableAllFields}
                                        required={isRequired}
                                        validate={
                                          isRequired
                                            ? requiredWithNotification(
                                                "Observações gerais"
                                              )
                                            : undefined
                                        }
                                      />
                                    );
                                  }}
                                </Fields>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Histórico">
                          <FormSectionWrapper>
                            <Field
                              component={CheckField}
                              name="semObservador"
                              type="checkbox"
                              className="form-control"
                              label="Não existe observador"
                              disabled={disableAllFields}
                            />

                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={CheckSetField}
                                      name="avaliacaoObservadorHistorico"
                                      label="Avaliação do observador"
                                      elements={goodRegularOrBad}
                                      disabled={value ? true : disableAllFields}
                                      validate={requiredWithNotificationCheckField(
                                        "Avaliação do observador", "semObservador"
                                      )}
                                      required={!value}
                                    />
                                  }}
                                </Field>

                                <AlertaCondicional
                                  nome="avaliacaoObservadorHistorico"
                                  valor="RUIM"
                                  mensagem="Informe o motivo da avaliação no campo de observações gerais"

                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={CheckSetField}
                                      name="substituicaoObservadorHistorico"
                                      label="Substituição do observador"
                                      elements={yesOrNo}
                                      disabled={value ? true : disableAllFields}
                                      validate={requiredWithNotificationCheckField(
                                        "Substituição do observador", "semObservador"
                                      )}
                                      required={!value}
                                    />
                                  }}
                                </Field>
                                <AlertaCondicional
                                  nome="substituicaoObservadorHistorico"
                                  valor="SIM"
                                  mensagem="Informe o motivo da substituição do observador no campo de observações gerais"
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  name="substituicaoObservadorHistorico"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const disabled = !(
                                      value && "SIM" === value
                                    );
                                    return (
                                      <>
                                        <Field
                                          component={DateTimeField}
                                          name="dataSubstituicaoObservadorHistorico"
                                          label="Data de substituição do observador"
                                          validate={
                                            disabled
                                              ? undefined
                                              : requiredWithNotification(
                                                  "Data de substituição do observador"
                                                )
                                          }
                                          required={!disabled}
                                          disabled={
                                            disableAllFields || disabled
                                          }
                                          key={disabled}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={CheckSetField}
                                      name="houvePagamentoObservador"
                                      label="Houve pagamento do observador ?"
                                      elements={yesOrNo}
                                      disabled={value ? true : disableAllFields}
                                      validate={requiredWithNotificationCheckField(
                                        "Houve pagamento do observador", "semObservador"
                                      )}
                                      required={!value}
                                    />
                                  }}
                                </Field>
                              </Col>
                            </Row>


                            <Row>
                              <CampoRelacionadoAHouvePagamentoObservador>
                                {(habilitado) => (
                                  <Col className="mt-3" md={4}>
                                    <>
                                      <Field
                                        component={SelectField}
                                        name="tipoPagamentoHistorico"
                                        label="Tipo de pagamento"
                                        elements={tiposPagamento}
                                        disabled={disableAllFields || !habilitado}
                                        validate={habilitado ? requiredWithNotification(
                                          "Tipo de pagamento"
                                        ) : undefined}
                                        key={habilitado}
                                        required={habilitado}
                                      />
                                    </>
                                  </Col>
                                )}
                              </CampoRelacionadoAHouvePagamentoObservador>
                              <CampoRelacionadoAHouvePagamentoObservador>
                                {(habilitado) => (
                                <Col className="mt-3" md={4}>
                                  <Field
                                    name="fimPagamentoHistorico"
                                    subscription={{ value: true }}
                                  >
                                    {({ input: { value } }) => (
                                      <Field
                                        component={DateTimeField}
                                        name="inicioPagamentoHistorico"
                                        label="Início pagamento"
                                        datePickerType="yearMonth"
                                        disabled={disableAllFields || !habilitado}
                                        selectsStart={!!value}
                                        key={habilitado}
                                        required={habilitado}
                                        endDate={value}
                                        validate={habilitado ? requiredWithNotification(
                                          "Início pagamento"
                                        ) : undefined}
                                      />
                                    )}
                                  </Field>
                                </Col>
                                )}
                              </CampoRelacionadoAHouvePagamentoObservador>
                              <CampoRelacionadoAHouvePagamentoObservador>
                                {(habilitado) => (
                                  <Col className="mt-3" md={4}>
                                    <>
                                      <Field
                                        name="inicioPagamentoHistorico"
                                        subscription={{ value: true }}
                                      >
                                        {({ input: { value } }) => (
                                          <Field
                                            component={DateTimeField}
                                            name="fimPagamentoHistorico"
                                            label="Fim pagamento"
                                            datePickerType="yearMonth"
                                            disabled={disableAllFields || !habilitado}
                                            selectsEnd={!!value}
                                            key={habilitado}
                                            required={habilitado}
                                            startDate={value}
                                            validate={habilitado ? requiredWithNotification(
                                              "Fim pagamento"
                                            ) : undefined}
                                          />
                                        )}
                                      </Field>
                                    </>
                                  </Col>
                                )}
                              </CampoRelacionadoAHouvePagamentoObservador>
                            </Row>

                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={CheckSetField}
                                      name="recolhimentoBoletimHistorico"
                                      label="Recolhimento de boletim"
                                      elements={yesOrNo}
                                      disabled={value ? true : disableAllFields}
                                      validate={requiredWithNotificationCheckField(
                                        "Recolhimento de boletim", "semObservador"
                                      )}
                                      required={!value}
                                    />
                                  }}
                                </Field>
                                <AlertaCondicional
                                  nome="recolhimentoBoletimHistorico"
                                  valor="SIM"
                                  mensagem="Informe os meses de boletins recolhidos"
                                />
                              </Col>
                              <Col className="mt-3" md={8}>
                                <Field
                                  name="recolhimentoBoletimHistorico"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const disabled = value !== "SIM";
                                    return (
                                      <>
                                        <Field
                                          component={TextField}
                                          maxLength={80}
                                          name="periodoRecBoletimHistorico"
                                          label="Período de recolhimento de boletim"
                                          validate={
                                            disabled
                                              ? undefined
                                              : requiredWithNotification(
                                                  "Período de recolhimento de boletim"
                                                )
                                          }
                                          required={!disabled}
                                          key={disabled}
                                          disabled={
                                            disableAllFields || disabled
                                          }
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={DateTimeField}
                                      name="dataCadernetasHistorico"
                                      label="Data cadernetas"
                                      disabled={value ? true : disableAllFields}
                                      validate={requiredWithNotificationCheckField(
                                        "Data cadernetas", "semObservador"
                                      )}
                                      datePickerType="yearMonth"
                                      required={!value}
                                    />
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={DateTimeField}
                                      name="dataEnvelopesHistorico"
                                      label="Data envelopes"
                                      disabled={value ? true : disableAllFields}
                                      datePickerType="yearMonth"
                                    />
                                  }}
                                </Field>
                              </Col>
                              {/*<Col className="mt-3" md={4}>*/}
                              {/*    <Field component={SelectField} name='recolhimentoLinigramaHistorico'*/}
                              {/*           label='Recolhimento de linigrama'*/}
                              {/*           elements={yesOrNo}*/}
                              {/*           disabled={disableAllFields}*/}
                              {/*           validate={required} required/>*/}
                              {/*</Col>*/}
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "nomeObservador" : "observador"}
                                  disabled={disableAllFields}
                                  label="Observador"
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Nivelamento">
                          <FormSectionWrapper>
                            <Row>
                              <Col className="offset-md-1 mt-0" md={2}>
                                <FieldLabel label="Identificação" />
                              </Col>
                              <Col className="mt-0" md={1}>
                                <FieldLabel label="Ref. (mm)" />
                              </Col>
                              <Col className="mt-0" md={1}>
                                <FieldLabel label="Ré (mm)" />
                              </Col>
                              <Col className="mt-0" md={1}>
                                <FieldLabel label="Vante (mm)" />
                              </Col>
                              <Col className="mt-0" md={1}>
                                <FieldLabel label="Plano (mm)" />
                              </Col>
                              <Col className="mt-0" md={1}>
                                <FieldLabel label="Cota (mm)" />
                              </Col>
                              <Col className="mt-0" md={1}>
                                <FieldLabel label="Correção" />
                              </Col>
                              <Col className="mt-0" md={2}>
                                {
                                !form.getState().values.nivelamentos &&
                                  push("nivelamentos", {})
                                }
                              </Col>
                            </Row>
                            <FieldArray
                              name="nivelamentos"
                              // validate={atLeastOneElement(
                              //   "Ao menos um registro de nivelamento é obrigatório"
                              // )}
                            >
                              {({ fields }) => {
                                return (
                                  <div>
                                    {fields.map((name, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col
                                            className="offset-md-1 mt-1"
                                            md={2}
                                          >
                                            <Field
                                              component={TextField}
                                              maxLength={20}
                                              name={`${name}.identificacao`}
                                              label=""
                                              disabled={disableAllFields}
                                            />
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            <Field
                                              component={NumberField}
                                              max={999999999}
                                              min={-999999999}
                                              semVirgula={true}
                                              name={`${name}.referencia`}
                                              disabled={disableAllFields}
                                              initialValue={0}
                                            />
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            <Field
                                              component={NumberField}
                                              max={999999999}
                                              min={-999999999}
                                              semVirgula={true}
                                              name={`${name}.re`}
                                              disabled={disableAllFields}
                                              initialValue={0}
                                            />
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            <Field
                                              component={NumberField}
                                              max={999999999}
                                              min={-999999999}
                                              semVirgula={true}
                                              name={`${name}.vante`}
                                              disabled={disableAllFields}
                                              initialValue={0}
                                            />
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            <Field
                                              component={NumberField}
                                              max={999999999}
                                              min={-999999999}
                                              semVirgula={true}
                                              name={`${name}.plano`}
                                              disabled={disableAllFields}
                                              initialValue={0}
                                              readOnly={true}
                                              tabIndex={-1}
                                              forcarValorZerado
                                            />
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            <Field name={`${name}.re`} subscription={{value: true}}>
                                              {({input:{value: valorDaRe}}) => {
                                                const exibirOValor = 0 === valorDaRe;
                                                return <Field
                                                    component={NumberField}
                                                    max={999999999}
                                                    min={-999999999}
                                                    decimaisAposvirgula={0}
                                                    semVirgula={true}
                                                    name={`${name}.cota`}
                                                    disabled={disableAllFields}
                                                    initialValue={0}
                                                    forcarValorZerado={exibirOValor}
                                                    onKeyUpCapture={(event) => {
                                                      if((fields.length - 1) === index && event.key === "Tab" ){
                                                        push("nivelamentos", {});
                                                      }
                                                    }}
                                                />
                                              }}
                                            </Field>
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            <Field
                                              component={NumberField}
                                              max={999999999}
                                              min={-999999999}
                                              semVirgula={true}
                                              name={`${name}.correcao`}
                                              disabled={disableAllFields}
                                              readOnly={true}
                                              tabIndex={-1}
                                              forcarValorZerado
                                              initialValue={0}
                                              forcarSinalPositivo
                                              customStylePerRange={[
                                                {
                                                  max: -9,
                                                  style: { color: "crimson" },
                                                },
                                                {
                                                  min: -10,
                                                  max: 10,
                                                  style: { color: "green" },
                                                },
                                                {
                                                  min: 11,
                                                  style: { color: "blue" },
                                                },
                                              ]}
                                            />
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                          { (form.getState().values.nivelamentos.length)  > 1 && !disableAllFields &&
                                            <BotaoIconeExclusao
                                              onClick={() =>
                                                fields.remove(index)
                                              }
                                            />
                                          }
                                          </Col>
                                          <Col className="mt-1" md={1}>
                                            {!disableAllFields &&

                                            <BotaoPadrao
                                              // Inseri um linha após o indice que clicou
                                              onClick={() => {
                                                  var nivelAtual = form.getState().values.nivelamentos;
                                                  if(index !== nivelAtual.length -1){
                                                    nivelAtual.splice(index + 1, 0, {});
                                                    //Apaga os elementos da lista atual
                                                    delete form.getState().values.nivelamentos;
                                                    // percorre a lista nova index
                                                    for( let i of nivelAtual){
                                                      // inseri o elemento da lista nova na atual
                                                      push("nivelamentos", i);
                                                    }
                                                  }else{
                                                    push("nivelamentos", {});
                                                  }
                                              }}
                                            >
                                              Adicionar Linha
                                            </BotaoPadrao>
                                            }
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </FormSectionWrapper>
                        </Tab>

                        {/* Exibe se o campo descargaLiquidaMedicao na aba medições estiver selecionada como sim devido a classe no Tab.Container*/}
                        <Tab title="Arquivos M.D.L." visible={this.exibirTab(form.getState().values.descargaLiquidaMedicao)} >
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovosArquivosMDL"
                                campoArquivosExistentes="idsArquivosExistentesMDL"
                                chaveListaArquivos="arquivosFluMDL"
                                bloquearTipos={true}
                                permitirZip={true}
                                disable={disableAllFields}
                                id="MDL"
                              />
                            </div>
                          </FormSectionWrapper>

                        </Tab>
                        {/* Exibe se o campo descargaSolidaMedicao na aba medições estiver selecionada como sim devido a classe no Tab.Container */}
                        <Tab title="Arquivos L.S.T." visible={this.exibirTab(form.getState().values.levantamentoMedicao)} >
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovosArquivosLST"
                                campoArquivosExistentes="idsArquivosExistentesLST"
                                chaveListaArquivos="arquivosFluLST"
                                bloquearTipos={true}
                                permitirZip={true}
                                disable={disableAllFields}
                                id="LST"
                              />
                            </div>
                          </FormSectionWrapper>

                        </Tab>
                        {/* Exibe se o campo levantamentoMedicao na aba medições estiver selecionada como sim devido a classe no Tab.Container*/}
                        <Tab title="Arquivos M.D.S." visible={this.exibirTab(form.getState().values.descargaSolidaMedicao)} >
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovosArquivosMDS"
                                campoArquivosExistentes="idsArquivosExistentesMDS"
                                chaveListaArquivos="arquivosFluMDS"
                                bloquearTipos={true}
                                permitirZip={true}
                                disable={disableAllFields}
                                id="MDS"
                              />
                            </div>
                          </FormSectionWrapper>

                        </Tab>
                        <Tab title="Fotos">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <div className="text-center" style={{color : 'red'}} >
                                <p>
                                  Obedeça a seguinte ordem para inserir as
                                  fotos:
                                </p>
                                <p>
                                  1 - Montante, 2 - Jusante, 3 - Seção de reguas e 4 - Nível de água.
                                </p>
                              </div>
                              <FileGridField
                                campoNovosArquivos="hashesNovasFotos"
                                campoArquivosExistentes="idsFotosExistentes"
                                chaveListaArquivos="fotosFlu"
                                bloquearTipos={true}
                                permitirImagens={true}
                                limiteArquivos={10}
                                permitirVideos={true}
                                disable={disableAllFields}
                                excluirCb={(id) => {
                                  this.setState({ fotosRemovidas: [ ...this.state.fotosRemovidas, id]})
                                }}
                              />
                              <p style={{ marginTop: 20, color: 'red', textAlign: 'center', fontSize: 14 }} >Incluir pelo menos 4 fotos (obrigatórias) conforme legenda e até 6 fotos extras (não obrigatórias), totalizando até 10 fotos.</p>
                            </div>
                          </FormSectionWrapper>

                        </Tab>
                        <Tab title="Boletins">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovosBoletins"
                                campoArquivosExistentes="idsBoletinsExistentes"
                                chaveListaArquivos="boletinsFlu"
                                bloquearTipos={true}
                                permitirImagens={true}
                                permitirVideos={true}
                                disable={disableAllFields}
                                id="BOLETINS"
                              />
                            </div>
                          </FormSectionWrapper>

                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }




}


export default connect(null, (dispatch) => ({
  limparArquivos() {
    dispatch(limparArquivos({ store: "fotosFlu" }));
    dispatch(limparArquivos({ store: "boletinsFlu" }));
    dispatch(limparArquivos({ store: "arquivosFluMDL" }));
    dispatch(limparArquivos({ store: "arquivosFluLST" }));
    dispatch(limparArquivos({ store: "arquivosFluMDS" }));
  },
  dispatch,
}))(PaginaFormRelatoriosFluviometricos);
