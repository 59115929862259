import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { defaultProps, propTypes } from "../formFieldCommonProps";
import FieldLabel from "../FieldLabel";
import Error from "../../Error";

import "./NumberField.css";
import { InputGroup } from "react-bootstrap";

class NumberField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = (event) => {
      let value = event.target.value;

      if (this.props.semVirgula){
        value = value.toString().replaceAll(',','');
        value = value.toString().replace(/,/g, '');
        value = value.toString().replaceAll('.','');
        value = parseFloat(value);
      }

      // if (this.props.max && value && value > this.props.max) {
      //   value = value.toString().slice(0,value.toString().length -1);
      // }

      if (this.props.max && value) {
        const quantidadeMaximaDigitos = this.props.max.toString().length;
        const temDecimaisAposVirgula = typeof this.props.decimaisAposvirgula === 'number';

        if (temDecimaisAposVirgula) {
          let [valorInteiro, valorDecimal] = value.toString().split('.');

          if (valorInteiro.length > quantidadeMaximaDigitos) {
            valorInteiro = valorInteiro.slice(0, valorInteiro.toString().length -1)
          }

          value = valorDecimal
            ? valorInteiro.concat(`.${valorDecimal}`)
            : valorInteiro;
        }

        if (!temDecimaisAposVirgula && value > this.props.max) {
          value = value.toString().slice(0,value.toString().length -1);
        }
      }

      if (this.props.forcarValorInteiro) {
        value = parseInt(value)
      }
      if (props.input) {
        props.input.onChange(value);
      }
      if (props.onChange) {
        props.onChange(value);
      }
    };

    this.inputRef = React.createRef();

    this.state = {
      jaApertouBackspaceParaApagarZeroForcado: false,
    };

    // https://stackoverflow.com/a/469362/7988516
    this.limparConteudo = () => {
      const self = this.inputRef.current;
      if (self) {
        const valido = self.validity.valid;
        const semValor = self.validity.valueMissing;
        const inseriuCaracterInvalido = self.validity.badInput;

        if (valido) {
          self.oldValue = self.value;
          // eslint-disable-next-line no-prototype-builtins
        } else if (
          self.hasOwnProperty("oldValue") &&
          semValor &&
          inseriuCaracterInvalido
        ) {
          self.value = self.oldValue;
        }
      }
    };
  }

  // Corrige o problema com não poder apagar o zero (0) inicial dos campos com forcarValorZerado = true.
  keyDown = (e) => {
    if (!this.state.jaApertouBackspaceParaApagarZeroForcado) {
      if (8 === e.keyCode) {
        // Backspace
        if (1 === e.target.value.length) {
          this.setState({
            jaApertouBackspaceParaApagarZeroForcado : true,
          })
          this.forceUpdate();
        }
      }
    }
  };
  // ./Corrige o problema com não poder apagar o zero (0) inicial dos campos com forcarValorZerado = true.


  get suportaEventosPassivos() {
    let passiveSupported = false;

    try {
      const options = {
        get passive() {
          return passiveSupported = true;
        }
      };

      window.addEventListener("test", options, options);
      window.removeEventListener("test", options, options);
    } catch (err) {
      passiveSupported = false;
    }

    return passiveSupported;
  }

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('wheel', function (e) {
        e.preventDefault();
      }, this.suportaEventosPassivos ? {
        passive: false
      } : false);
    }
  }

  render() {
    const name = this.props.name || (this.props.input && this.props.input.name);

    let value = '';

    if (this.props.value != null && this.props.value != undefined) {
      value = this.props.value;
    } else if(this.props.input && this.props.input.value != null && this.props.input.value != undefined) {
      value = this.props.input.value
    } else if(this.props.forcarValorZerado && !this.state.jaApertouBackspaceParaApagarZeroForcado) {
      value = 0;
    } else {
      value = this.props.input && this.props.input.value ? this.props.input.value : '';
    }

     /*
      this.props.value ||
      (this.props.input && this.props.input.value) ||
      (this.props.forcarValorZerado &&
        !this.state.jaApertouBackspaceParaApagarZeroForcado
        ? 0
        : (this.props.input && this.props.input.value ? this.props.input.value : ''));
      */

    if (this.props.forcarSinalPositivo && value && Number(value) > 0) {
      value = "+" + value;
    }

    if(this.props.semVirgula){
      value = value.toString().replace(/,/g, '');
      value = value.toString().replaceAll('.','');
      value = parseFloat(value);
    } else if (value && typeof (this.props.decimaisAposvirgula) === "number") {
      // converte o valor para String
      value = value.toString();
      // verifica se possui ponto ou virgula
      if((value.indexOf('.') !== -1)  || (value.indexOf(',') !== -1) ){
        // verifica se o tamanho do valor digitado é maior que as casas decimais permitidas
        if(value.length > (value.indexOf('.') + this.props.decimaisAposvirgula + 1)){
          // ignora o valor digitado acima das casas decimais permitidas
          value = value.slice(0, value.length -1)
          // converte em numero
          value = parseFloat(value).toFixed(this.props.decimaisAposvirgula);
        }
      }

    }
    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox && this.props.forcarSeparadorDecimalVirgula && value) {
      value = ("" + value).replace(".", ",");
    }

    // Isso resolve visualmente, mas o final-form retém o valor inserido, é utilizar propriedades nativas com funções de validação.
    // // não possibilita digitar valor maior que o valor maximo definido
    if (this.props.max && value) {
      const quantidadeMaximaDigitos = this.props.max.toString().length;
      const temDecimaisAposVirgula = typeof this.props.decimaisAposvirgula === 'number';

      if (temDecimaisAposVirgula) {
        let [valorInteiro, valorDecimal] = value.toString().split('.');

        if (valorInteiro.length > quantidadeMaximaDigitos) {
          valorInteiro = valorInteiro.slice(0, valorInteiro.toString().length -1)
        }

        value = valorDecimal
          ? valorInteiro.concat(`.${valorDecimal}`)
          : valorInteiro;
      }

      if (!temDecimaisAposVirgula && value > this.props.max) {
        value = value.toString().slice(0,value.toString().length -1);
      }
    }

    const className = !this.props.showArrow ? "withoutArrows text-right" : "text-right";
    let style = {};

    for (const customStyle of this.props.customStylePerRange) {
      const min =
        "undefined" !== typeof customStyle.min
          ? customStyle.min
          : Number.NEGATIVE_INFINITY;

      const max =
        "undefined" !== typeof customStyle.max
          ? customStyle.max
          : Number.POSITIVE_INFINITY;
      if (value <= max && value >= min) {
        style = {
          ...style,
          ...customStyle.style,
        };
      }
    }

    const fieldHint = this.props.accessibleHint
      ? `${this.props.label} = ${value}`
      : this.props.hint;

    return (
      <>
        {this.props.label && (
          <FieldLabel label={this.props.label} required={this.props.required} />
        )}

        <InputGroup className="mb-0 no-scroll">
          {this.props.textoPre && (
            <InputGroup.Prepend style={{ height: "35px" }}>
              <InputGroup.Text style={{ fontSize: ".9rem" }}>
                {this.props.textoPre}
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}

          <Form.Control
            lang="pt"
            pattern="[0-9]+([\.,][0-9]+)?"
            type={this.props.forcarSinalPositivo ? "text" : "number"}
            required={this.props.required}
            name={name}
            value={value}
            onChange={this.onChange}
            disabled={this.props.disabled}
            step={this.props.step}
            min={this.props.min}
            max={this.props.max}
            readOnly={this.props.readOnly}
            style={style}
            className={className}
            onKeyUpCapture={this.props.onKeyUpCapture}
            onKeyDown={this.keyDown}
            onInput={this.limparConteudo}
            onKeyUp={this.limparConteudo}
            onMouseUp={this.limparConteudo}
            onSelect={this.limparConteudo}
            onContextMenu={this.limparConteudo}
            onDrop={this.limparConteudo}
            ref={this.inputRef}
            tabIndex={this.props.tabIndex}
            title={fieldHint}
          />
        </InputGroup>

        {this.props.input && <Error name={name} />}
      </>
    );
  }
}

NumberField.propTypes = {
  ...propTypes,
  value: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.number,
  forcarValorInteiro: PropTypes.bool,
  showArrow: PropTypes.bool,
  textoPre: PropTypes.string,
  forcarValorZerado: PropTypes.bool,
  forcarSinalPositivo: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customStylePerRange: PropTypes.arrayOf(
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      style: PropTypes.object,
    })
  ),
  decimaisAposvirgula: PropTypes.number,
  hint: PropTypes.string,
  accessibleHint: PropTypes.bool,
};

NumberField.defaultProps = {
  ...defaultProps,
  step: 1,
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  customStylePerRange: [],
  showArrow: false,
  forcarValorZerado: false,
  forcarSinalPositivo: false,
  forcarValorInteiro: false,
  textoPre: "",
  hint: undefined,
  accessibleHint: false,
};

export default NumberField;
