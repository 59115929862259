import {connect} from "react-redux";
import JqxNotification from "jqwidgets-scripts/jqwidgets-react-tsx/jqxnotification";
import React from "react";
import {dismissGlobalNotification} from "../../reducers";
import "./Notificacao.css"
// TODO: make this component allow more than one notification at a time
class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.notificationRef = React.createRef();
        this.notificationMessage = '';
    }
    render() {
        const notification = this.props.notification;

        if (notification && notification.forcarExibicao){
            const key = notification.message;
      
            return <JqxNotification 
              ref={this.notificationRef} 
              key={key}
              width={250} 
              position={'top-right'} 
              opacity={0.9} 
              autoOpen={false}
              autoClose={true} 
              animationOpenDelay={800} 
              autoCloseDelay={3000}
              template={notification.type}
            >
              <div id='message-container'>{notification.message}</div>
            </JqxNotification>
          }
        if (notification && notification.message !== this.notificationMessage) {
            /* atualizar o key quando a mensagem muda força a reconstrução dos filhos do componente, nesse caso a mensagem */
            const key = notification.message;
            this.notificationMessage = notification.message;
            return <JqxNotification ref={this.notificationRef} key={key}
                width={250} position={'top-right'} opacity={0.9} autoOpen={false}
                autoClose={true} animationOpenDelay={800} autoCloseDelay={3000} template={notification.type}>
                <div id='message-container'>{notification.message}</div>
            </JqxNotification>
        }
        return <></>
    }
    componentDidUpdate() {
        if (this.notificationRef.current) {
            this.notificationRef.current.open();
        }
    }
}
const mapStateToProps = (state) => ({
    notification: state.global.currentNotification,
});
const mapDispatchToProps = (dispatch) => ({
    onClickToDismiss: () => {
        dispatch(dismissGlobalNotification())
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notification);